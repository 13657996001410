<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('availability_of_rooms')"

                        @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('availability_of_rooms')"
                              @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear" :export-excel="true"  @exportExcel="excelExport">
                <ValidationObserver ref="formModalValidate">
                    <b-row>
                        <b-col md="6">
                                <b-form-group :label="$t('start_date') + ' / ' + $t('end_date')">
                                    <ValidationProvider name="date" rules="required" v-slot="{ valid, errors }">
                                        <v-date-picker
                                            v-model="datatable.queryParams.filter.date"
                                            is-range
                                            :locale="'en'"
                                            :model-config="{ type: 'string', mask: 'MM/DD/YYYY' }"
                                        >
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <div class="border rounded-sm p-2 p-md-3 d-flex align-items-center"
                                                     :class="errors[0] ? 'box-border-color' : ''">
                                                    <div class="flex-grow-1">
                                                        <b-form-group class="m-0 mr-2">
                                                            <input
                                                                :value="inputValue.start"
                                                                v-on="inputEvents.start"
                                                                :placeholder="$t('start_date')"
                                                                class="form-control"
                                                            />
                                                        </b-form-group>
                                                    </div>
                                                    <div class="flex-grow-1">
                                                        <b-form-group class="m-0 ml-2">
                                                            <input
                                                                :value="inputValue.end"
                                                                v-on="inputEvents.end"
                                                                :placeholder="$t('end_date')"
                                                                class="form-control"
                                                            />
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                            </template>
                                        </v-date-picker>
                                    </ValidationProvider>
                                </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('room_type')">
                                <parameter-selectbox code="housing_room_types"
                                                     v-model="datatable.queryParams.filter.room_type"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group :label="$t('gender')">
                                <gender-selectbox v-model="datatable.queryParams.filter.gender" :showOther="false"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :queryParams="datatable.queryParams" :lineNumbers="false"
                       @on-page-change="onPageChange" @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>

            <CommonModal ref="addReservationModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()"
            >
                <template v-slot:CommonModalTitle>
                    {{ $t('create_reservation_request').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <AddReservationForm  :form="form"  :formId="formId" v-if="formProcess == 'add_reservation' && !isStudent"
                                        @createFormSuccess="createFormSuccess"/>
                    <AddReservationStudentForm :form="form" :formId="formId" v-if="formProcess == 'add_reservation_student' && isStudent"
                                        @createFormStudentSuccess="createFormStudentSuccess"/>
                </template>
            </CommonModal>


        </app-layout>
    </div>
</template>
<script>
//Template
import AppLayout from "@/layouts/AppLayout.vue";
import Header from "@/layouts/AppLayout/Header.vue"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile.vue"
//Component
import DatatableFilter from "@/components/datatable/DatatableFilter.vue";
import Datatable from "@/components/datatable/Datatable.vue";

//Other
import qs from "qs";
//Service
import AvailabilityOfRoomServices from "@/services/AvailabilityOfRoomServices";
import AddReservationForm from "@/modules/AvailabilityOfRooms/pages/AddReservationForm.vue";
import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox.vue";
import AddReservationStudentForm from "@/modules/AvailabilityOfRooms/pages/AddReservationStudentForm.vue";
//Page


export default {
    components: {
        AddReservationStudentForm,
        GenderSelectbox,
        AddReservationForm,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
    },
    metaInfo() {
        return {
            title: this.$t("availability_of_rooms"),
        };
    },

    data() {
        return {
            formId: 0,
            form: {},
            formProcess: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t('create_reservation_request'),
                                class: 'ri-add-line',
                                permission: 'housingreservation_store',
                                callback: (row) => {
                                    this.showModal(row);
                                },

                            },
                        ],
                    },
                    {
                        label: this.$t("Building"),
                        field: "housing_building_name",
                        sortable: true,
                    },
                    {
                        label: this.$t("Floor"),
                        field: "housing_floor",
                        sortable: false,
                    },
                    {
                        label: this.$t("Room"),
                        field: "housing_room_name",
                        sortable: false,
                    },
                    {
                        label: this.$t("Bed Type"),
                        field: "type_name",
                        sortable: false,
                    },
                    {
                        label: this.$t("Room Type"),
                        field: "room_type_name",
                        sortable: false,
                    },
                    {
                        label: this.$t("start_date"),
                        field: "start_date",
                        sortable: false
                    },
                    {
                        label: this.$t("end_date"),
                        field: "end_date",
                        sortable: false
                    },
                    {
                        label: this.$t("gender"),
                        field: "gender",
                        sortable: false
                    },
                    {
                        label: this.$t("monthly_fee"),
                        field: "fee",
                        sortable: false,
                        formatFn:(row)=>{
                           return  this.currencyFormat(row)
                        }
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    computed:{
        user() {
            return this.$store.getters["auth/getUser"];
        },
        isStudent() {
            return this.$store.getters["auth/isStudent"];
        },
    },
    methods: {
        formClear() {
            this.formId = null
            this.formProcess = null
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        async getRows() {
            let isValid = await this.$refs.formModalValidate.validate()
            if (!isValid) {
                return
            }
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            if (this.datatable.queryParams.filter.date.start && this.datatable.queryParams.filter.date.end) {

                this.datatable.queryParams.filter.check_in_date =this.datatable.queryParams.filter.date.start

                this.datatable.queryParams.filter.check_out_date = this.datatable.queryParams.filter.date.end
            }
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return AvailabilityOfRoomServices.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        showModal(row) {
            this.formId =row.id
            this.form=this.datatable.queryParams.filter.date
            this.formProcess = this.isStudent?'add_reservation_student':'add_reservation'
            this.$refs.addReservationModal.$refs.commonModal.show()
        },
        createFormSuccess() {
            this.formClear()
            this.$refs.addReservationModal.$refs.commonModal.hide()

        },
       async excelExport(){
            let isValid = await this.$refs.formModalValidate.validate()
            if (!isValid) {
                return
            }
            if (this.datatable.queryParams.filter?.date?.start && this.datatable.queryParams?.filter?.date?.end) {

                this.datatable.queryParams.filter.check_in_date =this.datatable.queryParams.filter.date.start
                this.datatable.queryParams.filter.check_out_date = this.datatable.queryParams.filter.date.end
            }
            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            config.responseType = 'arraybuffer';
            return AvailabilityOfRoomServices.excelExport(config)
                .then((response) => {
                        this._downloadFile(response, this.$t('availability_of_rooms') + '.xlsx');
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });

        },
        createFormStudentSuccess(){
            this.formClear()
            this.$refs.addReservationModal.$refs.commonModal.hide()

        }

    },
};
</script>

